import {CloudPayConfig} from "@app-core/configs/cp-config";
import {environment} from 'src/environments/environment';
import {AuthConfig} from './auth-config';
import {SignalrConfig} from './signalr-config';

export interface Config {
  production: boolean;
  host: string;
  orderHost: string;
  authHost: string;
  signalR: SignalrConfig;
  authConfig: AuthConfig;
  cloudPayConfig: CloudPayConfig;
  SENTRY_DSN: string;
  PROJECT: string;
  VERSION: string;
  IS_FULL_VERSION:boolean
}

export const Configs: Config = environment;
