import {Injectable} from '@angular/core';
import {Configs} from '@app-core/configs/configs';
import {Airport, CabinetOrder, City, Country, MainDictionary, Nationality,} from '@app-core/entities';
import {Observable} from 'rxjs';
import {BaseRestService} from './common/base-rest.service';

@Injectable()
export class ResourcesService {
  constructor(private readonly baseRestService: BaseRestService) {}
  private readonly allEndpoint: string = 'api/dictionaries/all';
  public getAllDictionaries = (): Observable<MainDictionary> =>
    this.baseRestService.findAsync(this.allEndpoint);

}
