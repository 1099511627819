import {Injectable} from '@angular/core';
import {Configs} from '@app-core/configs/configs';
import {CreateOrderResponse, NewOrderModel,} from '@app-core/models';
import {Observable} from 'rxjs';
import {BaseRestService} from './common/base-rest.service';
import {AvailableAdditionalLuggageRequest, PriceAdjustedDefaultAdditionalLuggage} from "@app-core/entities";

@Injectable()
export class OrdersApiService {
  private readonly addOrderEnpoint: string = 'createOrder';
  private readonly getOrderEnpoint: string = 'info';
  private readonly ordersEnpoint: string = 'api/orders';

  constructor(private readonly baseRestService: BaseRestService) {}

  // public getOrders = (
  //   pageSize: number,
  //   pageNumber: number,
  //   filter: Filters
  // ): Observable<OrdersReponseModel> =>
  //   this.baseRestService.findAsync(
  //     this.ordersEnpoint,
  //     {
  //       pageSize,
  //       pageNumber,
  //       ...filter,
  //     },
  //     Configs.orderHost
  //   );

  // public getOrderById = (orderId: number): Observable<CabinetOrder> =>
  //   this.baseRestService.getAsync(
  //     orderId,
  //     this.ordersEnpoint,
  //     '',
  //     Configs.orderHost
  //   );

  public createOrder = (
    newOrder: NewOrderModel
  ): Observable<CreateOrderResponse> =>
    this.baseRestService.postWithCustomResponse(
      newOrder,
      this.ordersEnpoint,
      Configs.orderHost
    );
  public getAvailableAdditionalLuggage = (payload: AvailableAdditionalLuggageRequest): Observable<PriceAdjustedDefaultAdditionalLuggage[]> =>
    this.baseRestService.postWithCustomResponse(payload, 'api/support/additional-luggage', Configs.orderHost);
}
