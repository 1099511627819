{
  "name": "site-front",
  "version": "1.0.7",
  "scripts": {
    "ng": "ng",
    "start:ka": "nx serve --configuration developmentKa -o --host local.avia.com --port 4210 --ssl true --ssl-cert local.avia.com.pem --ssl-key local.avia.com-key.pem",
    "start:ru": "nx serve --configuration developmentRu -o --host local.avia.com --port 4210 --ssl true --ssl-cert local.avia.com.pem --ssl-key local.avia.com-key.pem",
    "build": "nx build",
    "build:ka:prod": "nx build --configuration productionKa",
    "build:ru:prod": "nx build --configuration productionRu",
    "build:ka:stage": "nx build --configuration stageKa",
    "build:ru:stage": "nx build --configuration stageRu",
    "test": "nx test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.10",
    "@angular/cdk": "^18.2.10",
    "@angular/common": "^18.2.10",
    "@angular/compiler": "^18.2.10",
    "@angular/core": "^18.2.10",
    "@angular/forms": "^18.2.10",
    "@angular/platform-browser": "^18.2.10",
    "@angular/platform-browser-dynamic": "^18.2.10",
    "@angular/router": "^18.2.10",
    "@fortawesome/fontawesome-free": "6.6.0",
    "@microsoft/signalr": "6.0.25",
    "@ngneat/input-mask": "^5.4.0",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@ngxs-labs/dispatch-decorator": "^5.0.0",
    "@ngxs/form-plugin": "^18.1.4",
    "@ngxs/router-plugin": "^18.1.4",
    "@ngxs/store": "^18.1.4",
    "@sentry/angular": "8.41.0",
    "@sentry/esbuild-plugin": "^2.22.6",
    "@sentry/tracing": "7.114.0",
    "angular-svg-icon": "^18.0.2",
    "country-list-with-dial-code-and-flag": "^5.0.6",
    "flag-icons": "^7.2.3",
    "inputmask": "5.0.7",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.46",
    "ngx-scrollbar": "^10.0.1",
    "ngx-webstorage-service": "^5.0.0",
    "ngxs-reset-plugin": "^4.0.0",
    "oidc-client": "^1.11.5",
    "primeflex": "^3.3.1",
    "primeicons": "~7.0.0",
    "primeng": "~17.18.11",
    "rxjs": "~7.8.1",
    "tslib": "~2.8.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.10",
    "@angular-devkit/core": "^18.2.10",
    "@angular-devkit/schematics": "^18.2.10",
    "@angular/cli": "^18.2.10",
    "@angular/compiler-cli": "^18.2.10",
    "@angular/language-service": "^18.2.10",
    "@ngxs/devtools-plugin": "^18.1.4",
    "@nx/angular": "20.1.4",
    "@nx/workspace": "20.1.4",
    "@schematics/angular": "^18.2.10",
    "@types/inputmask": "5.0.3",
    "@types/jasmine": "4.0.3",
    "@types/node": "17.0.25",
    "jasmine-core": "4.1.0",
    "karma": "~6.3.18",
    "karma-chrome-launcher": "~3.1.1",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "nx": "20.1.4",
    "prettier": "2.8.1",
    "typescript": "~5.5.4"
  }
}
