import {Injectable} from '@angular/core';
import {Configs} from '@app-core/configs/configs';
import {CustomerSearchQuery, CustomerTicket, VariantsResponse} from '@app-core/models';
import {Observable} from 'rxjs';
import {BaseRestService} from './common/base-rest.service';

@Injectable()
export class SearchService {
  constructor(private readonly baseRestService: BaseRestService) {}
  private readonly searchEndpoint: string = 'api/search';

  public getVariant = (variantId: string,requestId:number): Observable<VariantsResponse> =>
    this.baseRestService.findAsync(
      `${this.searchEndpoint}/variants/${variantId}/${requestId}`,
      undefined,
      Configs.signalR.host
    );
  public getPreSearch = (hash: string): Observable<CustomerSearchQuery> =>
    this.baseRestService.findAsync(
      `${this.searchEndpoint}/hash/${hash}`,
      undefined,
      Configs.signalR.host
    );
}

// public getUserInfo = (): Observable<UserInfo> =>
// GET https://aviaoperator.com/api/userinfo
