import { HttpClient, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Configs} from '@app-core/configs/configs';
import {Observable} from 'rxjs';

export interface Filters {
  [index: string]:
    | number
    | string
    | boolean
    | number[]
    | string[]
    | Date
    | Date[]
    | undefined
    | null;
}

@Injectable()
export abstract class BaseRestService {
  constructor(private readonly httpClient: HttpClient) {}

  public getAsync = <TSource>(
    endpoint: string,
    appendix?: string,
    host: string = Configs.host
  ): Observable<TSource> => {
    const url = `${host}/${endpoint}/${appendix}`;
    return this.httpClient.get<TSource>(url);
  };

  public findAsync = <TSource>(
    endpoint: string,
    filters?: Filters,
    host: string = Configs.host
  ): Observable<TSource> => {
    let queryParams = new HttpParams();
    if (filters !== null && filters !== undefined) {
      for (const key of Object.keys(filters)) {
        const filter = filters[key];
        if (filter === null || filter === undefined) {
          continue;
        }

        if (this.isArrayDate(filter)) {
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          filter.forEach((value) => {
            queryParams = queryParams.set(key, value.toLocaleString());
          });
        } else if (this.isDate(filter)) {
          queryParams = queryParams.set(key, filter.toLocaleString());
        } else if (Array.isArray(filter)) {
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          filter.forEach((value: string | number) => {
            queryParams = queryParams.set(key, String(value));
          });
        } else {
          queryParams = queryParams.set(key, String(filter));
        }
      }
    }

    const url = `${host}/${endpoint}`;
    return this.httpClient.get<TSource>(url, { params: queryParams });
  };

  public postAsync = <TSource>(
    model: TSource,
    endpoint: string,
    host: string = Configs.host
  ): Observable<TSource> => {
    const url = `${host}/${endpoint}`;
    return this.httpClient.post<TSource>(url, model);
  };

  public postWithCustomResponse = <TSource, TResponse>(
    model: TSource,
    endpoint: string,
    host: string = Configs.host
  ): Observable<TResponse> => {
    const url = `${host}/${endpoint}`;
    return this.httpClient.post<TResponse>(url, model);
  };

  public putAsync = <TSource>(
    id: number,
    model: TSource,
    endpoint: string,
    appendix: string = '',
    host: string = Configs.host
  ): Observable<TSource> => {
    const url = `${host}/${endpoint}/${id}${appendix}`;
    return this.httpClient.put<TSource>(url, JSON.stringify(model));
  };

  public putWithCustomResponseAsync = <TSource, TResponse>(
    model: TSource,
    endpoint: string,
    appendix: string = '',
    host: string = Configs.host
  ): Observable<TResponse> => {
    const url = `${host}/${endpoint}/${appendix}`;
    return this.httpClient.put<TResponse>(url, JSON.stringify(model));
  };

  public deleteAsync = (
    id: number,
    endpoint: string,
    host: string = Configs.host
  ): Observable<void> => {
    const url = `${host}/${endpoint}/${id}`;
    return this.httpClient.delete<void>(url);
  };
  public patchAsync = <TSource, TBody>(
    endpoint: string,
    appendix: string = '',
    host: string = Configs.host,
    body?: TBody
  ): Observable<TSource> => {
    const url = `${host}/${endpoint}/${appendix}`;
    return this.httpClient.patch<TSource>(url, JSON.stringify(body));
  };
  private isDate = (
    filter: number | string | boolean | number[] | string[] | Date | Date[]
  ): filter is Date => {
    return (filter as Date).toDateString !== undefined;
  };

  private isArrayDate = (
    filter: number | string | boolean | number[] | string[] | Date | Date[]
  ): filter is Date[] => {
    return (
      Array.isArray(filter) && (filter[0] as Date).toDateString !== undefined
    );
  };
}
