import {Inject, Injectable} from '@angular/core';
import {Configs} from '@app-core/configs/configs';
import {CabinetOrder, CreateAdditionalService, User} from '@app-core/entities';
import { PaymentCurrency } from "@app-core/enums/currency.enum";
import {PaymentTypeEnum} from "@app-core/enums/payment-type.enum";
import { ChangePassword, ChangeUserInfo, CreateUrlPaymentResponse } from "@app-core/models";
import {Observable} from 'rxjs';
import {BaseRestService} from './common/base-rest.service';
import {PaymentStatusTypes} from "@app-core/enums";
import {IS_FULL_VERSION_TOKEN} from "@app-core/constants";

@Injectable()
export class CabinetApiService {
  private readonly cabinetEnpoint: string = 'api/cabinet';
  private readonly payEnpoint: string = 'api/cabinet/pay';
  private readonly usersEnpoint: string = 'api/users';
  private readonly customerCabinetEnpoint: string = 'api/customer-cabinet';
  private readonly isGe:boolean = !this.isFullToken;
  constructor(@Inject(IS_FULL_VERSION_TOKEN) private isFullToken: boolean,private readonly baseRestService: BaseRestService) {}

  public getOrders = (userId: string): Observable<CabinetOrder[]> =>
    this.baseRestService.findAsync(
      `${this.cabinetEnpoint}/${userId}/${this.isGe}`,
      undefined,
      Configs.orderHost
    );

  public addService = (orderId: number, newService: CreateAdditionalService): Observable<CabinetOrder> =>
    this.baseRestService.postWithCustomResponse<CreateAdditionalService, CabinetOrder>(
      newService,
      `${this.cabinetEnpoint}/${orderId}/add-service`,
      Configs.orderHost,
    );
  public getUserInfo = (): Observable<User> =>
    this.baseRestService.getAsync(this.usersEnpoint, 'info', Configs.authHost);

  public patchPassword = (payload: ChangePassword): Observable<void> =>
    this.baseRestService.patchAsync(
      this.customerCabinetEnpoint,
      'password',
      Configs.authHost,
      payload
    );

  public changeUserInfo = (payload: ChangeUserInfo): Observable<void> =>
    this.baseRestService.putWithCustomResponseAsync(
      payload,
      this.customerCabinetEnpoint,
      '',
      Configs.authHost
    );
  /**
   *
   * @param callbackData раньше пеймент пейлер айди
   * @returns
   */
  public getOrderByPaymentPaylerId = (
    callbackData: string
  ): Observable<CabinetOrder> =>
    this.baseRestService.getAsync(
      this.payEnpoint,
      `${callbackData}/get-order`,
      Configs.orderHost
    );
  /**
   * Редиректит на оплату
   * ответ PayParameters в строке | string
   * @param orderId - QueryParams
   * @param paymentTypeId - QueryParams
   * @param paymentCurrencyId - QueryParams
   * @returns
   */
  public toPay = (orderId: string, paymentTypeId: PaymentTypeEnum,paymentCurrencyId: PaymentCurrency): Observable<CreateUrlPaymentResponse> =>
    this.baseRestService.findAsync<CreateUrlPaymentResponse>(
      this.payEnpoint,
      { orderId,paymentTypeId,paymentCurrencyId },
      Configs.orderHost
    );

  public checkStatus = (
    callbackData: string
  ): Observable<{ status: PaymentStatusTypes; isFirstPayment: boolean, orderId:number }> =>
    this.baseRestService.getAsync(
      this.payEnpoint,
      callbackData,
      Configs.orderHost
    );

  // public getOrders = (
  //   pageSize: number,
  //   pageNumber: number,
  //   filter: Filters
  // ): Observable<OrdersReponseModel> =>
  //   this.baseRestService.findAsync(
  //     this.ordersEnpoint,
  //     {
  //       pageSize,
  //       pageNumber,
  //       ...filter,
  //     },
  //     Configs.orderHost
  //   );

  // public getOrderById = (orderId: number): Observable<CabinetOrder> =>
  //   this.baseRestService.getAsync(
  //     orderId,
  //     this.ordersEnpoint,
  //     '',
  //     Configs.orderHost
  //   );
}
