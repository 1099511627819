import packageJson from '../../package.json';

export const DEFAULT_ENVIRONMENT = {
  production: false,
  host: 'https://dev-core.aviaoperator.com',
  orderHost: 'https://dev-office.aviaoperator.com',
  authHost: 'https://dev-auth.aviaoperator.com',
  signalR: {
    host: 'https://dev-search.aviaoperator.com',
    searchHub: {
      hubName: 'customer-search',
      sendRequestMethodName: 'Send',
      recievedResponseMethodName: 'CustomerSearchResponse',
    },
  },
  authConfig: {
    clientId: 'ro.dev-site',
    redirectUri: 'https://local.avia.com:4210/auth-callback',
    responseType: 'code',
    scope:
      'openid profile email search-core order-service-api IdentityServerApi',
    authority: 'https://dev-auth.aviaoperator.com/',
    postLogoutRedirectUri: 'https://local.avia.com:4210/signout-callback',
    silentRedirectUri: 'https://local.avia.com:4210/silent_renew.html',
    automaticSilentRenew: true,
    loadUserInfo: true,
    filterProtocolClaims: true,
    accessTokenExpiringNotificationTime: 4,
  },
  cloudPayConfig:{
    publicId: 'pk_dc4d072eb1e7baa538a5f6d303804',
    skin: 'mini'
  },
  SENTRY_DSN:
    'https://ef56c02d1fd54e8a9ebc222769ae0de8@o164331.ingest.us.sentry.io/4504152130912256',
  PROJECT: packageJson.name,
  VERSION: packageJson.version,
  IS_FULL_VERSION: true
};

