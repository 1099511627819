import {Injectable} from '@angular/core';
import {BaseRestService} from './common/base-rest.service';

@Injectable()
export class UserService {
  constructor(private readonly baseRestService: BaseRestService) {}

  // public getUserInfo = (): Observable<User> =>
  //   this.baseRestService.findAsync(
  //     this.userInfoEnpoint,
  //     undefined,
  //     Configs.userHost
  //   );
}
