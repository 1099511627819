import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseRestService} from './common/base-rest.service';
import {Announcement} from "@app-core/models";

@Injectable()
export class AnnouncementService {
  private readonly announcementsEndpoint: string = 'api/announcements';
  constructor(private readonly baseRestService: BaseRestService) {}

  public getAnnouncements = (): Observable<Announcement[]> =>
    this.baseRestService.findAsync(this.announcementsEndpoint);
  // ниже для офиса
  // public getAnnouncementById = (id: number): Observable<Announcement[]> =>
  //   this.baseRestService.findAsync(`${this.announcementsEndpoint}/${id}`);

  // public createAnnouncement = (payload: Announcement): Observable<Announcement> =>
  //   this.baseRestService.postAsync(payload,this.announcementsEndpoint);

  // public changeAnnouncement = (payload: Announcement): Observable<Announcement> =>
  //   this.baseRestService.putAsync(payload.id,payload,this.announcementsEndpoint);

  // public removeAnnouncement = (id: number): Observable<void> =>
  //   this.baseRestService.deleteAsync(id,this.announcementsEndpoint);
}
