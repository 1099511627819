import { Inject, Injectable } from '@angular/core';
import {BaseRestService} from "@app-core/api-services/common/base-rest.service";
import {IS_FULL_VERSION_TOKEN} from "@app-core/constants";
import { Observable, of, pluck, tap } from 'rxjs';

@Injectable({
  providedIn:'root'
})
export class LocalizationApiService {

  constructor(@Inject(IS_FULL_VERSION_TOKEN) private isFullToken: boolean,private readonly baseRestService: BaseRestService) {}

  public getTranslationFromAssets = (lang: string): Observable<object> =>
   this.baseRestService.getAsync(
          `assets/i18n`,
     `${lang}${this.isFullToken ? '_full':''}.json`,
     '.'
        );

}
