import {Injectable} from '@angular/core';

@Injectable()
export class AuthService {
  constructor() {}
  //   export const getToken = (userName: string, password: string): Promise<GetTokenResponse> => {

  //     return new Promise((resolve, reject) => {
  //         fetch(GET_TOKEN_URL, {
  //             method: 'POST',
  //             headers: {
  //                 "Content-Type": "application/x-www-form-urlencoded"
  //             },
  //             body: `grant_type=password&username=${userName}&password=${password}`
  //         }).then(async response => {
  //             if (response) {
  //                 if (response.status == 200) {
  //                     const success = await response.json() as SuccessAuth;
  //                     resolve({
  //                         success: success,
  //                         error: null
  //                     });
  //                 } else {
  //                     const error = await response.json() as NotSuccessAuth;
  //                     resolve({
  //                         success: null,
  //                         error: error
  //                     });
  //                 }

  //             } else {
  //                 reject(response);
  //             }
  //         }, error => {
  //             reject(error);
  //         });
  //     });
  // };
}
