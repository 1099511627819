import {AnnouncementService} from './announcement.service';
import {AuthService} from './auth.service';
import {CabinetApiService} from './cabinet.service';
import {BaseRestService} from './common/base-rest.service';
import {OrdersApiService} from './order.service';
import {SearchService} from './search.service';
import {UserService} from './user.service';

export * from './common/base-rest.service';
export * from './auth.service';
export * from './order.service';
export * from './resources.service';
export * from './resources-en.service';
export * from './user.service';
export * from './search.service';
export * from './cabinet.service';
export * from './announcement.service';
export * from './localization.service';

export const API_SERVICES: any[] = [
  BaseRestService,
  AuthService,
  OrdersApiService,
  UserService,
  SearchService,
  CabinetApiService,
  AnnouncementService,
];
